import JPG_1 from "../../../../assets/images/raster/pages/de/main/Guides/guides_1.jpg";
import JPG_1_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@2x.jpg";
import JPG_1_1200 from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@1200.jpg";
import JPG_1_1200_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@1200_2x.jpg";
import JPG_1_992 from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@992.jpg";
import JPG_1_992_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@992_2x.jpg";
import JPG_1_768 from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@768.jpg";
import JPG_1_768_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@768_2x.jpg";
import JPG_1_Mob from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@mob.jpg";
import JPG_1_Mob_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@mob_2x.jpg";

import WEBP_1 from "../../../../assets/images/raster/pages/de/main/Guides/guides_1.webp";
import WEBP_1_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@2x.webp";
import WEBP_1_1200 from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@1200.webp";
import WEBP_1_1200_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@1200_2x.webp";
import WEBP_1_992 from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@992.webp";
import WEBP_1_992_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@992_2x.webp";
import WEBP_1_768 from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@768.webp";
import WEBP_1_768_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@768_2x.webp";
import WEBP_1_Mob from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@mob.webp";
import WEBP_1_Mob_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_1@mob_2x.webp";

import JPG_2 from "../../../../assets/images/raster/pages/de/main/Guides/guides_2.jpg";
import JPG_2_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@2x.jpg";
import JPG_2_1200 from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@1200.jpg";
import JPG_2_1200_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@1200_2x.jpg";
import JPG_2_992 from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@992.jpg";
import JPG_2_992_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@992_2x.jpg";
import JPG_2_768 from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@768.jpg";
import JPG_2_768_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@768_2x.jpg";
import JPG_2_Mob from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@mob.jpg";
import JPG_2_Mob_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@mob_2x.jpg";

import WEBP_2 from "../../../../assets/images/raster/pages/de/main/Guides/guides_2.webp";
import WEBP_2_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@2x.webp";
import WEBP_2_1200 from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@1200.webp";
import WEBP_2_1200_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@1200_2x.webp";
import WEBP_2_992 from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@992.webp";
import WEBP_2_992_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@992_2x.webp";
import WEBP_2_768 from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@768.webp";
import WEBP_2_768_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@768_2x.webp";
import WEBP_2_Mob from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@mob.webp";
import WEBP_2_Mob_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_2@mob_2x.webp";

import JPG_3 from "../../../../assets/images/raster/pages/de/main/Guides/guides_3.jpg";
import JPG_3_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@2x.jpg";
import JPG_3_1200 from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@1200.jpg";
import JPG_3_1200_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@1200_2x.jpg";
import JPG_3_992 from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@992.jpg";
import JPG_3_992_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@992_2x.jpg";
import JPG_3_768 from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@768.jpg";
import JPG_3_768_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@768_2x.jpg";
import JPG_3_Mob from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@mob.jpg";
import JPG_3_Mob_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@mob_2x.jpg";

import WEBP_3 from "../../../../assets/images/raster/pages/de/main/Guides/guides_3.webp";
import WEBP_3_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@2x.webp";
import WEBP_3_1200 from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@1200.webp";
import WEBP_3_1200_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@1200_2x.webp";
import WEBP_3_992 from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@992.webp";
import WEBP_3_992_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@992_2x.webp";
import WEBP_3_768 from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@768.webp";
import WEBP_3_768_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@768_2x.webp";
import WEBP_3_Mob from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@mob.webp";
import WEBP_3_Mob_2x from "../../../../assets/images/raster/pages/de/main/Guides/guides_3@mob_2x.webp";

const IDEA_GUIDES_LIST = [
  {
    picture: {
      JPG: JPG_1,
      JPG_2x: JPG_1_2x,
      JPG_1200: JPG_1_1200,
      JPG_1200_2x: JPG_1_1200_2x,
      JPG_992: JPG_1_992,
      JPG_992_2x: JPG_1_992_2x,
      JPG_768: JPG_1_768,
      JPG_768_2x: JPG_1_768_2x,
      JPG_Mob: JPG_1_Mob,
      JPG_Mob_2x: JPG_1_Mob_2x,
      WEBP: WEBP_1,
      WEBP_2x: WEBP_1_2x,
      WEBP_1200: WEBP_1_1200,
      WEBP_1200_2x: WEBP_1_1200_2x,
      WEBP_992: WEBP_1_992,
      WEBP_992_2x: WEBP_1_992_2x,
      WEBP_768: WEBP_1_768,
      WEBP_768_2x: WEBP_1_768_2x,
      WEBP_Mob: WEBP_1_Mob,
      WEBP_Mob_2x: WEBP_1_Mob_2x,
    },
    typePicture: "big",
    title: `Amazon Bestseller: Die Liste der 10 meistverkauften Produkte in ${process.env.YEAR}`,
    text: `Erlernen Sie die Marktanalyse-Checkliste, um die besten Produkte zu finden und zu identifizieren.`,
    href: "https://amzsc.amzgb.com/blog/how-to-find-perfect-products-to-sell-on-amazon/",
  },
  {
    picture: {
      JPG: JPG_2,
      JPG_2x: JPG_2_2x,
      JPG_1200: JPG_2_1200,
      JPG_1200_2x: JPG_2_1200_2x,
      JPG_992: JPG_2_992,
      JPG_992_2x: JPG_2_992_2x,
      JPG_768: JPG_2_768,
      JPG_768_2x: JPG_2_768_2x,
      JPG_Mob: JPG_2_Mob,
      JPG_Mob_2x: JPG_2_Mob_2x,
      WEBP: WEBP_2,
      WEBP_2x: WEBP_2_2x,
      WEBP_1200: WEBP_2_1200,
      WEBP_1200_2x: WEBP_2_1200_2x,
      WEBP_992: WEBP_2_992,
      WEBP_992_2x: WEBP_2_992_2x,
      WEBP_768: WEBP_2_768,
      WEBP_768_2x: WEBP_2_768_2x,
      WEBP_Mob: WEBP_2_Mob,
      WEBP_Mob_2x: WEBP_2_Mob_2x,
    },
    title: `Wie Sie im Jahr ${process.env.YEAR} auf Amazon Geld verdienen können`,
    text: "Entdecken Sie bewährte Techniken und Strategien, mit denen Sie ein erfolgreiches Amazon-Geschäft aufbauen können.",
    href: "https://amzsc.amzgb.com/blog/how-to-make-money-on-amazon-a-step-by-step-guide/",
  },
  {

    picture: {
      JPG: JPG_3,
      JPG_2x: JPG_3_2x,
      JPG_1200: JPG_3_1200,
      JPG_1200_2x: JPG_3_1200_2x,
      JPG_992: JPG_3_992,
      JPG_992_2x: JPG_3_992_2x,
      JPG_768: JPG_3_768,
      JPG_768_2x: JPG_3_768_2x,
      JPG_Mob: JPG_3_Mob,
      JPG_Mob_2x: JPG_3_Mob_2x,
      WEBP: WEBP_3,
      WEBP_2x: WEBP_3_2x,
      WEBP_1200: WEBP_3_1200,
      WEBP_1200_2x: WEBP_3_1200_2x,
      WEBP_992: WEBP_3_992,
      WEBP_992_2x: WEBP_3_992_2x,
      WEBP_768: WEBP_3_768,
      WEBP_768_2x: WEBP_3_768_2x,
      WEBP_Mob: WEBP_3_Mob,
      WEBP_Mob_2x: WEBP_3_Mob_2x,
    },
    title: `Trendprodukte auf Amazon und wie man sie im Jahr ${process.env.YEAR} findet`,
    text: "Erfahren Sie, wie Sie Amazon-Produktrecherche-Tools verwenden, um Trendprodukte zu finden und zu überprüfen.",
    href: "https://amzsc.amzgb.com/blog/amazon-fba-business/",
  }
];

export default IDEA_GUIDES_LIST;