import React, { useContext } from "react";
import { MediaContext } from "../../../../store/context/media-context";
import PropTypes from "prop-types";
import { PAGES } from "../../../../helpers/constants";
import { getTrueClasses } from "../../../../helpers";
import Title from "./Title";
import Points from "./Points";
import LicenseEmailCollectContainer from "../../../../containers/common/forms/LicenseEmailCollectContainer";
import Offer from "./Offer";
import Screen from "./Screen";
import "./index.scss";

const HomeIntro = (props) => {
  const {
    isPaid,
    setIsPaid,
    isSecondStateCollectEmail,
    setIsSecondStateCollectEmail,
    isLoading,
    isOpacity,
    setIsOpacity,
    homeIntroRef,
  } = props;
  const media = useContext(MediaContext);
  return (
    <section ref={homeIntroRef} className="PgDMain-HI" id="home">
      <div className="container">
        <div className="PgDMain-HI-row">
          <div className="PgDMain-HI-info">
            <Title />
            <Points />
            <LicenseEmailCollectContainer
              pageLoading={isLoading}
              pageFormState={!isLoading && isSecondStateCollectEmail}
              updatePageFormState={setIsSecondStateCollectEmail}
              page={PAGES.MAIN_PAGE}
              buttonTextFirstState="Probieren Sie AMZScout kostenlos aus!"
              buttonTextSecondState={
                isPaid
                  ? "INS IHR KONTO EINLOGGEN"
                  : "PROBIEREN SIE AMZSCOUT KOSTENLOS AUS!"
              }
              customClass={getTrueClasses(
                "PgDMain-HI-emailCollect",
                isOpacity && "emailCollect__opacity"
              )}
              isOpacity={isOpacity}
              setIsOpacity={setIsOpacity}
              setIsPaid={setIsPaid}
              isCheckLicense
              language="de"
              customPlaceholder={media.isMobile ? "example@amzsc.amzgb.com" : ""}
            />
            <Offer
              isLoading={isLoading}
              isSecondStateCollectEmail={isSecondStateCollectEmail}
              isPaid={isPaid}
              isOpacity={isOpacity}
            />
          </div>
          <Screen />
        </div>
      </div>
    </section>
  );
};

HomeIntro.propTypes = {
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsPaid: PropTypes.func,
  isPaid: PropTypes.bool,
};

export default HomeIntro;
